const pathToRegexp = require('path-to-regexp');
const md5 = require('js-md5');
import { LruCached } from 'utils';
import { AJAX_AUTH_NAME } from 'constants';

const cached = new LruCached(64);

const proxyMethod = function(target, request, args) {
  args[1] = args[1] ? args[1] : {};
  const config = getApi(args[0], args[1] ? args[1].params : {});
  // 如果是模块，则开始解析 method 和 uri
  if (!_.isString(config) || !_.isEmpty(config.uri)) {
    args[0] = args[1];
    args[0].method = config.method;
    args[0].url = config.uri;
    args[0].params = args[0].params || {};
    if (config.auth === true) {
      args[0].params[AJAX_AUTH_NAME] = true;
    }
    // 添加随机数
    args[0].params._ran = Math.random();
  }
  // 检查是否 cached
  if (config.cached) {
    // 从缓存中获取
    const key = md5(JSON.stringify(args[0]));
    let obj = cached.get(key);
    if (_.isUndefined(obj)) {
      obj = request.apply(target, args);
      cached.put(key, obj);
    } else {
      console.log('request %o cached is %o', args[0], obj);
    }
    return obj;
  }
  return request.apply(target, args);
};
/**
 * 只处理 request 方法, function (uri/module_name, config)
 */
// const AxiosProxy = {
//   apply(target, ctx, args) {
//     args[1] = args[1] ? args[1] : {};
//     const config = getApi(args[0], args[1] ? args[1].params : {});
//     // 如果是模块，则开始解析 method 和 uri
//     if (!_.isString(config) || !_.isEmpty(config.uri)) {
//       args[0] = args[1];
//       args[0].method = config.method;
//       args[0].url = config.uri;
//       args[0].params = args[0].params || {};
//       if (config.auth === true) {
//         args[0].params[AJAX_AUTH_NAME] = true;
//       }
//     }
//     // 检查是否 cached
//     if (config.cached) {
//       // 从缓存中获取
//       const key = md5(JSON.stringify(args[0]));
//       let obj = cached.get(key);
//       if (_.isUndefined(obj)) {
//         obj = Reflect.apply(target, ctx, args);
//         cached.put(key, obj);
//       } else {
//         console.log('request %o cached is %o', args[0], obj);
//       }
//       return obj;
//     }
//     return Reflect.apply(target, ctx, args);
//   },
// };

/**
 * 解析 uri
 * @param {String} uri 解析的 uri
 * @param {String} params  参数
 */
function getApi(module, params) {
  // 如果是字符串，则表示走的是 实际 url 而不是api
  if (_.isString(module)) {
    console.warn('后端访问的 api[%s] 地址最好是定义在apis中', module);
    return module;
  }
  let keys = [];
  let config = Object.assign(
    {},
    {
      method: 'GET',
      uri: '',
      auth: true,
      cached: false,
    },
    module,
  );
  pathToRegexp(config.uri, keys);
  //开始替换
  if (keys.length > 0) {
    keys.forEach(key => {
      if (!params[key.name]) {
        throw new Error(`API name: ${name}. You are using dynamic params but ${key.name} not existed in your params`);
      }
      config.uri = config.uri.replace(`:${key.name}`, params[key.name] || 'undefined');
    });
    // 删除 对应的 keys
    keys.forEach(key => {
      delete params[key.name];
    });
  }

  return config;
}
export default axiosInstance => {
  //axiosInstance.request = new Proxy(axiosInstance.request, AxiosProxy);
  let orRequest = axiosInstance.request;
  axiosInstance.request = function() {
    let args = [];
    for (let i = 0; i < arguments.length; i++) {
      args[i] = arguments[i];
    }
    return proxyMethod(axiosInstance, orRequest, args);
  };
};
