<template>
  <div class="plateNavigation" style="width: 100%">
    <div class="section" style="width: 100%">版块导航</div>
    <a-menu
      style="width: 100%"
      :openKeys="openKeys"
      :defaultSelectedKeys="conversionUrl"
      @openChange="onOpenChange"
      mode="inline"
    >
      <template v-for="item in plates">
        <a-sub-menu :key="item.id">
          <span slot="title">
            <span>{{ item.cate_name }}</span>
          </span>
          <template v-for="children in item.children">
            <a-menu-item :key="children.link_url + '/' + children.id" @click="to(children)">
              <router-link
                :class="conversionUrl==children.link_url + '/' + children.id?'active':''"
                :to="children.id == 54 ? 'xxx' : children.link_url + '/' + children.id"
              >{{ children.cate_name }}</router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      openKeys: [],
      plateNavigations: [],
      plates: [],
      conversionUrl: [],
    };
  },
  watch: {},
  created() {
    this.$router.afterEach((to, from) => {
      // console.log(to);
      this.conversionUrl = [to.fullPath];
     
    });
    this.conversionUrl = [window.location.pathname];
    
    if (!_.isEmpty(this.openKey)) {
      this.openKeys = this.openKey;
    }
  },

  mounted() {
    this.plate();
  },
  computed: {
    ...mapState('user', ['openKey']),
  },
  methods: {
    ...mapMutations('user', ['getOpenKey']),
    to(item) {
      if (item.id == 54) {
        window.open(item.link_url);
        return;
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = [openKeys.find(key => this.openKeys.indexOf(key) === -1)];
      this.openKeys = latestOpenKey;
      localStorage.setItem('data', JSON.stringify(this.openKeys));
      this.getOpenKey(JSON.parse(localStorage.getItem('data')));
      // console.log(this.openKeys);
      // if (this.plates.filter(item => item.id == latestOpenKey).length !== 0) {
      //   this.openKeys = openKeys;
      //   console.log('1111111', this.openKeys);
      // } else {
      //   this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      //   console.log('222222222', this.openKeys);
      // }
    },
    plate() {
      this.$request(Apis.mun.PLATE).then(data => {
        this.plates = data.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  color: #ffcc00 !important;
}
</style>
<style lang="less">
.plateNavigation {
  cursor: pointer;
  .section {
    line-height: 48px;
    height: 48px;
    padding-left: 24px;
    background: #333333;
    font-size: 16px;
    font-weight: 600px;
    color: #fff;
  }
  .ant-menu {
    background: #333333 !important;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 0;
    .ant-menu-submenu {
      background: #333333;
      font-size: 16px;
      font-weight: 600px;
      color: #fff;
      border-color: #333333;
      .ant-menu-sub {
        background-color: #666666 !important;

        .ant-menu-item {
          background: #666666;
          border: 0;
          width: 100%;
          a {
            color: #fff;
          }
          a:hover {
            color: #ffcc00;
          }
        }
      }
    }
  }
}
</style>