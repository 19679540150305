import axios from 'axios';
import Vue from 'vue';
import AxiosProxy from '@/configs/apis/api-request';
// 统一增加 headers
const axiosInstance = axios.create({
  headers: {
    accept: 'application/json;charset=UTF-8',
  },
});
let vue = null;
let lastVisitUrl = null;
AxiosProxy(axiosInstance);

/**
 * 添加一个 header， 如果 如果 value 不存在，则直接删除
 */
axiosInstance.header = function (name, value, scope = 'common') {
  if (_.isUndefined(value)) {
    delete axiosInstance.defaults.headers[scope][name];
  } else {
    axiosInstance.defaults.headers[scope][name] = value;
  }
  return axiosInstance;
};
// add vue axios
Vue.prototype.$request = axiosInstance.request;
global.$$request = axiosInstance.request;
//记录 最后访问url
Vue.mixin({
  mounted() {
    if (this.$route) {
      lastVisitUrl = this.$route.fullPath;
    }
  },
});
export default function (v) {
  vue = v;
}
