export default {
    MUNS: {
        uri: '/api/cate/shouyedh', 
    },
    FORUMMUNS: {
        uri: '/api/cate/luntandh', 
    },
    PLATE: {
        uri: '/api/cate/bankdh', 
    },
}