export default {
  name: 'user',
  namespaced: true,
  state: {
    isLogin: false,
    uid: '',
    nikeName: '',
    avatar: '',
    info: undefined,
    openKey: undefined,

  },
  mutations: {
    login(state, user) {
      state.isLogin = true;
      state.info = user;
      
    },
    logout(state) {
      state.isLogin = false;
    },
    logoimg(state,img){
      state.info.file = img;
    },
    getOpenKey(state, key) {
      state.openKey = key;
    }
  },

};