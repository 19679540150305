const files = require.context('./modules', false, /-api\.js$/);
let df;
let APIS = {};
let spIndex = -1;
files.keys().forEach(key => {
  df = files(key).default;
  name = key.substring(key.lastIndexOf('/') + 1, key.lastIndexOf('-'));
  spIndex = name.indexOf('-');
  // 处理 文件名中存在 - ，将其名称改成驼峰
  while (spIndex !== -1) {
    name = name.substring(0, spIndex) + name.substring(spIndex + 1, spIndex + 2).toUpperCase() + name.substring(spIndex + 2);
    spIndex = name.indexOf('-');
  }
  APIS[name] = df || {};
});
global.Apis = APIS;
export default { ...APIS };
