var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plateNavigation", staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "section", staticStyle: { width: "100%" } }, [
        _vm._v("版块导航")
      ]),
      _c(
        "a-menu",
        {
          staticStyle: { width: "100%" },
          attrs: {
            openKeys: _vm.openKeys,
            defaultSelectedKeys: _vm.conversionUrl,
            mode: "inline"
          },
          on: { openChange: _vm.onOpenChange }
        },
        [
          _vm._l(_vm.plates, function(item) {
            return [
              _c(
                "a-sub-menu",
                { key: item.id },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("span", [_vm._v(_vm._s(item.cate_name))])
                  ]),
                  _vm._l(item.children, function(children) {
                    return [
                      _c(
                        "a-menu-item",
                        {
                          key: children.link_url + "/" + children.id,
                          on: {
                            click: function($event) {
                              return _vm.to(children)
                            }
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              class:
                                _vm.conversionUrl ==
                                children.link_url + "/" + children.id
                                  ? "active"
                                  : "",
                              attrs: {
                                to:
                                  children.id == 54
                                    ? "xxx"
                                    : children.link_url + "/" + children.id
                              }
                            },
                            [_vm._v(_vm._s(children.cate_name))]
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }