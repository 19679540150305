export default {
  DETAILS: {
    uri: '/api/index/details',
    method: 'POST',
  },
  ARTICLES: {
    uri: '/api/index/articles',
    method: 'POST',
  },
};
