var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "adv" },
    [
      _c(
        "a-carousel",
        { attrs: { autoplay: "" } },
        [
          _vm._l(_vm.lists, function(item) {
            return [
              _c(
                "a",
                {
                  key: item.id,
                  attrs: { url: item.link_url, target: "_blank" }
                },
                [
                  _c("div", {
                    staticClass: "adv-bg",
                    style: {
                      backgroundImage: "url(" + item.img_src + ")",
                      backgroundSize: "cover"
                    }
                  }),
                  _c("div", { staticClass: "position" })
                ]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }