export default [
    {
        path: '/details/:id',
    
        component: () => import( /* webpackChunkName: "details" */ '@/views/details/Index.vue'),
    },
    {
        path: '/forum/details/:id',
    
        component: () => import( /* webpackChunkName: "jkForum/details" */ '@/views/jkForum/details/Index.vue'),
    }
    ]