export default {
    PRODUCTRES: {
        uri: '/api/login/productres',
        method: 'POST'
    },
    //我的帖子列表

    WODETZ: {
        uri: '/api/index/wodetz',
        method: 'POST'
    },
    //我的点赞帖子列表
    WODETZZS: {
        uri: '/api/index/wodetzzs',
        method: 'POST'
    },
    //我的帖子评论回复
    WODELYS: {
        uri: '/api/index/wodelys',
        method: 'POST'
    },
    //官方公告接口
    GFGG: {
        uri: '/api/index/gfgg',
        method: 'POST'
    },
    //更新用户信息
    UPDATED: {
        uri: '/api/login/gxusers',
        method: 'POST'
    },  
    //查询会员等级
    DENGJI: {
        uri: '/api/login/dengji',
        method: 'POST'
    },
    //
    XIUGAI: {
        uri: '/api/login/xiugai',
        method: 'POST'
    },
    //积分
    JIFEN:{
        uri: '/api/index/wojifen',
        method: 'POST'
    },
}