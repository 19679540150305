export default {
    WORKS: {
        uri: '/api/login/fangxiang',
    },
    PERSONAL: {
        uri: '/api/login/grreg',
        method: 'POST',
    },
    COMPANY: {
        uri: '/api/login/qyreg',
        method: 'POST',
    },
    //验证用户名是否存在
    USERNAME: {
        uri: '/api/login/username',
        method: 'POST',
    },
    //验证手机号是否存在
    METHOD: {
        uri: '/api/login/mobile',
        method: 'POST',
    },
};