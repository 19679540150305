import Vue from 'vue';
import Vuex from 'vuex';


// -- 导入 modules
let modules = {};
const files = require.context('./modules', false, /-store\.js$/);
let df, name;
files.keys().forEach(key => {
  df = files(key).default;
  name = key.substring(key.lastIndexOf('/') + 1, key.lastIndexOf('-'));
  modules[name] = df;
});
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    initCount: 0,
    title: 'Loading',
    clientSize: {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    },
    
    /**
     * 页面 id
     */
    pageId: '',
    /**
     * 是否 全屏高度
     */
    isFullHeight: false,
   
  },
  mutations: {
    changeScreen(state) {
     
      state.clientSize = {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
     
    },
    loading(state) {
      state.initCount++;
    },
    loaded(state) {
      state.initCount--;
    },
    /**
     * 设置 页面id
     * @param {*}} state
     * @param {*} id
     */
    setPageId(state, id) {
      if (id) {
        state.pageId = id;
      } else {
        state.pageId = '';
      }
    },
    setFullHeight(state, isFull) {
      state.isFullHeight = isFull ? true : false;
    },
  },
  modules: {
    ...modules,
  },
});
