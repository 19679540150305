export default {

    ADV1: {
        uri: '/api/index/adimg1'
        
    },
    ADV2: {
        uri: '/api/index/adimg2',
       
    },
    ADV3: {
        uri: '/api/index/adimg3',
        
    }

}