export default {
    //论坛轮播图接口
    LTIMGS: {
        uri: '/api/index/lbtimgs',
        method: 'POST',
    },
    //最新图片
    NEWIMAGE: {
        uri: '/api/index/zclimgs',
        method: 'POST',
    },
    //帖子列表（最新）（全部时间）接口
    POSTRES: {
        uri: '/api/merchant/postRes',
        method: 'POST',
    },
    //帖子详情接口
    POSTDEAIL: {
        uri: '/api/merchant/postdeail',
        method: 'POST',
    },
    //文章收藏
    STAR: {
        uri: '/api/merchant/collect',
        method: 'POST',
    },
    //文章取消收藏
    QUSTAR: {
        uri: '/api/merchant/qucollect',
        method: 'POST',
    },
    //文章点赞
    LIKES: {
        uri: '/api/merchant/likes',
        method: 'POST',
    },
    //最新评分用户
    XGJIFEN: {
        uri: '/api/merchant/xgjifen',
        method: 'POST',
    },
    //全部用户评分
    XGPf: {
        uri: '/api/merchant/xgpf',
        method: 'POST',
    },
    //判断帖子详情用户是否已经收藏
    PDSC: {
        uri: '/api/merchant/pdsc',
        method: 'POST',
    },
    //判断帖子详情用户是否已经点赞
    PDDZ: {
        uri: '/api/merchant/pddz',
        method: 'POST',
    },
    //相关帖子列表
    XGTZ: {
        uri: '/api/merchant/xgtz',
        method: 'POST',
    },
    //回帖内容列表
    HUITIEXINXI: {
        uri: '/api/merchant/huitiexinxi',
        method: 'POST',
    },
    //游客回帖接口
    REVIEWS: {
        uri: '/api/merchant/reviews',
        method: 'POST',
    },
    //楼主留言接口
    WORDS: {
        uri: '/api/merchant/words',
        method: 'POST',
    },

    //用户发帖接口
    POSTS: {
        uri: '/api/merchant/posts',
        method: 'POST',
    },
    //返回标签列表
    BIAOQIAN: {
        uri: '/api/merchant/biaoqian',
    },
    //热门(查看)帖子列表的接口
    RMPOSTRES: {
        uri: '/api/merchant/rmpostRes',
        method: 'POST',
    },
    //热贴帖子列表的接口
    RTPOSTRES: {
        uri: '/api/merchant/rtpostRes',
        method: 'POST',
    },
    //精华帖子列表的接口
    JHPOSTRES: {
        uri: '/api/merchant/jhpostRes',
        method: 'POST',
    },
    //论坛最热主题帖子列表（10条）接口
    ZRZTS: {
        uri: '/api/merchant/zrzts',
        method: 'POST',
    },
    //用户活跃度排行榜
    HYDS: {
        uri: '/api/merchant/hyds',
    },
    //热帖排行榜
    RTPHS: {
        uri: '/api/merchant/rtphs',
    },
    //论坛帖子列表
    LTTZRES: {
        uri: '/api/merchant/lttzRes',
    },
    //帖子总数，会员总数，最新会员
    ZONGSHU: {
        uri: '/api/merchant/zongshu',
    },
    //举报内容
    JUBAOS: {
        uri: '/api/merchant/jubaos',
        method: 'POST',
    },
    //今日帖子总数，主题数，最新排名
    JINRISHU: {
        uri: '/api/merchant/jinrishu',
        method: 'POST',
    },
    //判断分类栏目用户是否已经收藏
    PDLMSC: {
        uri: '/api/merchant/pdlmsc',
        method: 'POST',
    },
    //判断栏目详情用户是否已经订阅
    PDLMDY: {
        uri: '/api/merchant/pdlmdy',
        method: 'POST',
    },
    //分类栏目收藏
    FENLEISHOUCANG: {
        uri: '/api/merchant/fenleishoucang',
        method: 'POST',
    },
    //分类栏目帖取消收藏接口
    FENLEIQUXIAO: {
        uri: '/api/merchant/fenleiquxiao',
        method: 'POST',
    },
    //分类栏目订阅
    FENLEIDINGY: {
        uri: '/api/merchant/fenleidingy',
        method: 'POST',
    },
    //
    FENLEIQUXIAODINGY: {
        uri: '/api/merchant/fenleiquxiaody',
        method: 'POST',
    },
    //获取帖子详情内容里的第一张图片f
    DYTUPIAN: {
        uri: '/api/merchant/dytupian',
        method: 'POST',
    },
    //论坛首页轮播图接口（三个）
    LTSYIMGS: {
        uri: '/api/index/ltsyimgs',
    },
    //获取帖子详情内容里的大图图片(2张)
    DYTUPIANS: {
        uri: '/api/merchant/dytupians',
        method: 'POST',
    },
};