<template>
  <div class="adv">
    <a-carousel autoplay>
      <template v-for="item in lists">
        <a :key="item.id" :url="item.link_url" target="_blank">
          <div
            class="adv-bg"
            :style="{ backgroundImage: 'url(' + item.img_src + ')',backgroundSize:'cover' }"
          ></div>
          <div class="position"></div>
        </a>
      </template>
    </a-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.adv();
  },
  methods: {
    adv() {
      return this.$request(Apis.adv.ADV2).then(data => {
        this.lists = data.data.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.adv {
  margin-bottom: 30px;
  .ant-carousel {
    height: 100%;

    .slick-initialized {
      height: 100%;
      .slick-list {
        height: 100%;
      }
      .slick-slide {
        width: 100%;
        height: 100%;
        position: relative;
        a {
          display: block;
          position: relative;
          width: 100%;
        }
        .adv-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: auto;
          background: #eee;
          background-size: cover;
        }
        .position {
          padding-bottom: 150%;
        }
      }
    }
  }
}
</style>