export default {

    NOMECAROUSEL: {
        uri: '/api/index/imgs',

    },
    ARTS: {
        uri: '/api/index/arts',
    },
    //网站地图接口
    MAD: {
        uri: '/api/index/mad',
    },
    //搜索文章信息
    SEARCHS: {
        uri: '/api/index/searchs',
        method: 'POST',
    },
    //搜索帖子信息
    SEARCHSF: {
        uri: '/api/merchant/searchs',
        method: 'POST',
    },
    //展示大图信息
    DATUS: {
        uri: '/api/index/datus',
        method: 'POST',
    },
    //网站配置接口
    CONFS: {
        uri: '/api/conf/confs',
        method: 'POST',
    },
    //获取底部导航接口
    CATEDB: {
        uri: '/api/cate/catedb',
        method: 'POST',
    },
}