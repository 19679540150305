<template>
  <div class="page-banna">
    <a :href="Banna.link_url" target="_blank">
      <div
        class="banna-bg"
        :style="{ backgroundImage: 'url(' + Banna.img_src + ')',backgroundSize:'cover' }"
      ></div>
      <div class="position"></div>
    </a>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  props: {
    Banna: VueTypes.object,
  },
};
</script>
<style lang="less" scoped>
.page-banna {
  width: 100%;
  position: relative;
  a {
    display: block;
    width: 100%;
    position: relative;
  }
  .banna-bg {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: url('/assets/images/page-banna.png') no-repeat;
    background-size: cover;
  }
  .position {
    padding-bottom: 40%;
  }
}
</style>
