<template>
  <a-locale-provider :locale="locale">
    <div class="is-full-height" id="is-full-height">
      <router-view />
    </div>
  </a-locale-provider>
</template>
<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'normalize.css';
import '@/styles/all.less';
import { mapMutations, mapState } from 'vuex';

import ResizeObserver from 'resize-observer-polyfill';
export default {
  data() {
    return {
      locale: zh_CN,
      bottom: 0,
    };
  },
  components: {},
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
  mounted() {},
  destroyed() {},
  computed: {},

  methods: {},
};
</script>
