export default {
  IMAGES: {
    uri: '/api/albums/upload/images',
  },
  //返回用户上传的照片书素材(未用)
  NOIMAGES: {
    uri: '/api/albums/upload/images',
  },
  //返回用户上传的照片书素材(未用)新
  NOIMAGESPAGES: {
    uri: '/api/albums/upload/images/pages',
  },
  //删除
  DELETENOIMAGES: {
    uri: '/api/albums/images/:userImgId',
    method: 'DELETE',
  },
  //全清
  DELETECLEARNOIMAGES: {
    uri: '/api/albums/images/clear',
    method: 'DELETE',
  },
  UPLOAD: {
    uri: '/api/oss/upload?userImageType=ALBUM',
  },
  CREATE: {
    uri: '/api/albums',
    method: 'POST',
  },

};