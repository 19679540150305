export default {
    LOGIN: {
        uri: '/api/login/login',
        method: 'POST',
    },
    FORGET: {
        uri: '/api/cate/wjmm',
        method: 'POST',
    },
    //退出
    EXIT: {
        uri: '/api/login/out',
        method: 'POST',
    },
    //验证码
    CODE: {
        uri: '/api/login/sends',
        method: 'POST',
    },
    //验证验证码是否正确
    YZCODE: {
        uri: '/api/login/yzcode',
        method: 'POST',
    },
};