import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { LOGIN_PAGE, AUTH_TYPES, GET_LOGIN_PAGE } from 'constants';
Vue.use(Router);
// 处理 子模块
let routers = [];
const files = require.context('./modules', false, /-router\.js$/);
files.keys().forEach(key => {
  routers = [...files(key).default, ...routers];
});

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "layout" */ '@/layouts/BasicLayout.vue'),
      children: [...routers],
    },
  ],
});

router.afterEach((to, from) => NProgress.done());



export default (store, init) => {
  router.$store = store;
  router.$init = init;
  return router;
};

