import Vue from 'vue';
import {
  Timeline,
  Button,
  LocaleProvider,
  Col,
  Row,
  Icon,
  Layout,
  Menu,
  Avatar,
  Spin,
  Cascader,
  Collapse,
  Checkbox,
  Modal,
  Upload,
  Message,
  Form,
  Input,
  Select,
  Radio,
  InputNumber,
  DatePicker,
  TimePicker,
  Popconfirm,
  Alert,
  Progress,
  Drawer,
  Pagination,
  Tabs,
  Divider,
  Carousel,
  Tooltip,
  Switch,
  Tag,
  ConfigProvider,
  Anchor
} from 'ant-design-vue';
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Avatar);
Vue.use(Spin);
Vue.use(Cascader);
Vue.use(Collapse);
Vue.use(Checkbox);
Vue.use(Modal);
Vue.use(Upload);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Radio);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Anchor)
Vue.use(Alert);
Vue.use(Popconfirm);
Vue.use(Progress);
Vue.use(Drawer);
Vue.use(Pagination);
Vue.use(Tabs);
Vue.use(Divider);
Vue.use(Tooltip);
Vue.use(Timeline);
Message.config({
  maxCount: 1,
});
Vue.prototype.$message = Message;
Vue.use(Carousel);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(LocaleProvider);
Vue.use(ConfigProvider);