var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-banna" }, [
    _c("a", { attrs: { href: _vm.Banna.link_url, target: "_blank" } }, [
      _c("div", {
        staticClass: "banna-bg",
        style: {
          backgroundImage: "url(" + _vm.Banna.img_src + ")",
          backgroundSize: "cover"
        }
      }),
      _c("div", { staticClass: "position" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }