export default {

    ABOUT: {
        uri: '/api/index/abouts'
        
    },
    
    ARTRES: {
        uri: '/api/index/artRes'
        
    },
}