/**
 * 该文件用于定义自己的使用的组件
 */
import Vue from 'vue';
import VueCookie from 'vue-cookie';
import VueLazyload from 'vue-lazyload';
import VueTouch from 'vue-touch';
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import VueTypes from 'vue-types';
//
import Banna from '../components/Banna.vue';
import Advertising from '../components/Advertising.vue';
import Advertising2 from '../components/Advertising2.vue';
import Advertising3 from '../components/Advertising3.vue';
import plateNavigation from '../components/plateNavigation.vue'

moment.locale('zh-cn');
Vue.use(VueTypes);
Vue.use(VueTouch);
Vue.use(VueScrollTo);
Vue.use(VueCookie);
// 设置滑动方向，以防止全部警用
VueTouch.config.swipe = {
  direction: 'horizontal',
};
Vue.use(require('vue-moment'), {
  moment,
});
// 注册 懒加载图片
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/assets/404.jpg',
  //loading: '/assets/loading.gif',
  attempt: 1,
});

/**
 * uri 编码
 */
_.encodeUri = function (value) {
  return encodeURIComponent(value ? value : '', 'UTF8');
};

/**
 * 查询单个元素
 */
_.findOne = function (arrays, value) {
  let index = _.findIndex(arrays, value);
  return index == -1 ? undefined : arrays[index];
};

/**
 * 是否包含
 */
_.includeWith = function (arrays, value) {
  return _.findIndex(arrays, value) != -1;
};

/**
 * 等待
 */
_.await = function (delay) {
  return new Promise(function (res) {
    if (_.isFunction(delay)) {
      delay(res);
    } else {
      setTimeout(function () {
        res();
      }, delay);
    }
  });
};

/**
 * 处理多个promise问题
 * @param promises 要处理的promise列表
 * @param forceFail 是否失败一个就强制失败
 */
_.promiseAll = function (promises, forceFail = true) {
  if (!_.isArray(promises)) {
    return promises;
  }
  return new Promise(function (resolve, reject) {
    let result = [],
      count = promises.length;
    let isError = false;
    promises.forEach((o, i) => {
      o.then(data => {
        result[i] = data;
      })
        .catch(e => {
          if (isError) {
            console.error(e);
            return;
          }
          if (forceFail) {
            isError = true;
            reject(e);
          } else {
            result[i] = {
              _isError: true,
              ex: e,
            };
          }
        })
        .finally(callback);
    });

    function callback() {
      count--;
      if (count === 0) {
        // 如果全失败，才认为是失败
        if (result.filter(o => o._isError).length === result.length) {
          reject(result);
        } else {
          resolve(result);
        }
      }
    }
  });
};

// 定义一个 format 函数

Vue.prototype.$format = function (message, params) {
  params = params || {};
  message = message || '';
  for (let [key, o] of Object.entries(params)) {
    message = message.replace(new RegExp(`\{${key}\}`, 'ig'), '' + o);
  }
  return message;
};
// 定义日志
Vue.prototype.$log = {
  error: function () {
    console.error.apply(this, arguments);
  },
  info: function () {
    console.info.apply(this, arguments);
  },
  warn: function () {
    console.warn.apply(this, arguments);
  },
  debug: function () {
    if (process.env.NODE_ENV !== 'production') {
      console.log.apply(this, arguments);
    }
  },
};

// 将 _ 都放入 vue 上下文中
Vue.prototype.$_ = _;

// 定义全局组件
Vue.component('Banna', Banna);
Vue.component('Advertising', Advertising);
Vue.component('Advertising2', Advertising2);
Vue.component('Advertising3', Advertising3);
Vue.component('plateNavigation', plateNavigation)