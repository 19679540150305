// 按需加载页面 css 导入工具
const importPageCss = name => import( /* webpackChunkName: "[request]" */ `@/styles/pages/${name}-page.less`);

/**
 * 先进先出 cache
 */
class LruCached {
  constructor(size) {
    this._$sources = [];
    this._$size = size;
  }
  /**
   * 设置值
   * @param {String} key 设置的key
   * @param {*} value 设置的值
   */
  put(key, value) {
    // 首先检查原来是否有
    let index = _.findIndex(this._$sources, {
      name: key
    });
    if (index === -1) {
      this._$sources.push({
        name: key,
        value: value,
      });
      // 检查 size 是否超出限制，如果超出，则移除一条
      if (this._$sources.length > this._$size) {
        this._$sources.splice(this._$sources.length - 1, 1);
      }
    } else {
      let obj = this._$sources[index];
      // 删除
      this._$sources.splice(index, 1);
      obj.value = value;
      //放到开头
      this._$sources.push(obj);
    }
  }

  /**
   * 根据key获取对应值
   * @param {String} key 获取值的key
   */
  get(key) {
    let index = _.findIndex(this._$sources, {
      name: key
    });
    if (index === -1) {
      return undefined;
    }
    let obj = this._$sources[index];
    // 删除
    this._$sources.splice(index, 1);
    //放到开头
    this._$sources.push(obj);
    return obj.value;
  }
  /**
   * 移除特定key数据
   * @param {Stirng} key  移除的key
   */
  remove(key) {
    let index = _.findIndex(this._$sources, {
      name: key
    });
    if (index === -1) {
      return undefined;
    }
    let obj = this._$sources[index];
    this._$sources.splice(index, 1);
    return obj.value;
  }

  /**
   * 当期那数量
   * @return {Number} 数量
   */
  size() {
    return this._$sources.length;
  }

  clear() {
    this._$sources = [];
  }
}

export {
  importPageCss,
  LruCached
};