import Vue from 'vue';
import '@babel/polyfill';
import App from './App.vue';
import store from '@@/stores/index';
import router from '@@/routers/index';
import '@@/antd';
import axios from '@@/axios';
import '@@/customize';
import '@@/apis/index';

/* 复制 */
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;

Vue.config.productionTip = false;
const vue = new Vue({
  router: router(
    store,
    () =>
      new Promise((res, rej) => {
        if (vue.$store.state.initCount === 0) {
          res();
        } else {
          vue.$watch('$store.state.initCount', n => {
            try {
              if (n === 0) {
                res();
              }
            } catch (e) {
              console.error(e);
            }
          });
        }
      }),
  ),
  store,
  render: h => h(App),
}).$mount('#app');
Vue.use(VueClipboard);
axios(vue);
