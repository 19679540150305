import { render, staticRenderFns } from "./Advertising2.vue?vue&type=template&id=4bb5aa84&scoped=true&"
import script from "./Advertising2.vue?vue&type=script&lang=js&"
export * from "./Advertising2.vue?vue&type=script&lang=js&"
import style0 from "./Advertising2.vue?vue&type=style&index=0&id=4bb5aa84&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb5aa84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Avik\\Desktop\\liutao\\jiakuluntan\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4bb5aa84', component.options)
    } else {
      api.reload('4bb5aa84', component.options)
    }
    module.hot.accept("./Advertising2.vue?vue&type=template&id=4bb5aa84&scoped=true&", function () {
      api.rerender('4bb5aa84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Advertising2.vue"
export default component.exports